import React from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { Switch, BrowserRouter as Router, Route, Link, useLocation, Redirect, NavLink } from "react-router-dom";
import JoinActivity from '../view/consumer/JoinActivity';
import CouponCode from '../view/consumer/CouponCode';
import SellerRoute from './sellerRoute';
import '../style/App.less';
import '../style/App.scss';
import '../style/baseStyle.scss';
import CouponsList from "../view/consumer/couponsList";
import 'zarm/dist/zarm.css';
import { ConfigProvider } from 'zarm';
import {Locale} from "zarm/types/config-provider/PropsType";
// @ts-ignore
// import enUS from 'zarm/lib/config-provider/locale/en_US'; // 导入美国英语配置


const { Header, Footer, Sider, Content } = Layout;

function App() {  

  return (
      <ConfigProvider primaryColor="#ff1e1e">
        <div className="App">
          <div className="user">
              <Router>
                  <Switch>
                       <Route path="/c/:id" exact render={route=><CouponCode id={route.match.params.id} />} />
                       <Route path="/join" exact render={route=><JoinActivity />} />
                       <Route path="/coupons/list/:customerToken" exact render={route=><CouponsList customerToken={route.match.params.customerToken}/>} />

                      <Route path="/seller">
                        <div className="seller-root">
                          <SellerRoute />
                        </div>
                      </Route>

                   </Switch>
               </Router>
          </div>
        </div>
      </ConfigProvider>
  );
}

export default App;
